  export default {
    name: 'scorerLoginIndex',
    components: {},
    data() {
      return {
        queryForm: {},
        tableData:[]
      }
    },
    watch: {

    },
    created() {

    },
    mounted() {

    },
    destroyed() {
      // window.removeEventListener('storage', this.afterQRScan)
    },
    methods: {
    fastQuery(){

    }
    }
  }
